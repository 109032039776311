import { Grid, Box, Container, LinearProgress } from '@material-ui/core';
import { useContext, useEffect } from 'react';
import AgendaContext from '../../contexts/AgendaContext';
import Calendar from './Calendar/Calendar';
import LoaderBackdrop from '../shared/BackdropLoader/BackdropLoader';
import ActivePacks from './ActivePacks/ActivePacks';
import DayAgenda from './DayAgenda/DayAgenda';
import CustomSnackbar from '../shared/CustomSnackbar/CustomSnackbar';
import ErrorComponent from '../shared/ErrorComponent/ErrorComponent';
import { Helmet } from 'react-helmet';

function Agenda() {
  return (
    <Box marginTop={4}>
      <Container maxWidth='md'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActivePacks />
          </Grid>
          <Grid item xs={12} md={6}>
            <Calendar />
          </Grid>
          <Grid item xs={12} md={6}>
            <DayAgenda />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default function AgendaWrapper() {
  const {
    fetchAgenda,
    loading,
    error,
    bookingResponse,
    selectedDate,
  } = useContext(AgendaContext);

  useEffect(() => {
    fetchAgenda!();
  }, [fetchAgenda]);

  if (error) return <ErrorComponent>{error}</ErrorComponent>;
  if (!selectedDate && loading) return <LinearProgress />;

  const snackbar = bookingResponse
    ? {
        severity: bookingResponse[0] ? 'success' : 'error',
        message: bookingResponse[1],
      }
    : undefined;

  return (
    <>
      <Helmet>
        <title>Book Now | Kimys'classroom</title>
      </Helmet>
      <LoaderBackdrop open={loading!} />
      <Agenda />
      <CustomSnackbar
        open={!!snackbar}
        severity={snackbar?.severity as 'success' | 'error'}
        children={snackbar?.message}
      />
    </>
  );
}
