import {
  Container,
  Grid,
  Box,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { Appointment } from '../../types/Appointment';
import Lesson from './Lesson';
import ErrorComponent from '../shared/ErrorComponent/ErrorComponent';
import useLessonsHistory from './useLessonsHistory';
import { Helmet } from 'react-helmet';

// import { lessons } from './mock';

function LessonsHistory({ lessons }: Props) {
  return (
    <Container maxWidth='md'>
      <Box paddingY={3}>
        <Typography variant='h2' gutterBottom color='primary'>
          Lessons History
        </Typography>
        <Grid container spacing={1}>
          {lessons.map((lesson) => (
            <Grid item xs={12} key={lesson.id}>
              <Lesson lesson={lesson} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}

type Props = {
  lessons: Appointment[];
};

export default function LessonsHistoryWrapper() {
  const { lessons, loading, error } = useLessonsHistory();

  if (error) return <ErrorComponent>{error}</ErrorComponent>;
  if (loading) return <LinearProgress />;
  return (
    <>
      <Helmet>
        <title>Lessons history | Kimys'classroom</title>
      </Helmet>
      <LessonsHistory lessons={lessons} />;
    </>
  );
}
