import { Box, Typography, makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: '40px 1fr',
  },
  descriptionStyle: {
    lineHeight: '1.2',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.87rem',
    },
  },
}));

function Badge(props: Props) {
  const { root, descriptionStyle } = useStyles();
  const { title, description, Icon } = props;
  return (
    <div className={root}>
      <Icon />
      <Box>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography align='justify' className={descriptionStyle}>
          {description}
        </Typography>
      </Box>
    </div>
  );
}

type Props = {
  title: string;
  description: string;
  Icon: FunctionComponent;
};

export default Badge;
