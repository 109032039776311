import {
  makeStyles,
  Link,
  Container,
  Typography,
  TextField,
  Button,
  Grid,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import ErrorComponent from '../shared/ErrorComponent/ErrorComponent';
import LoaderBackdrop from '../shared/BackdropLoader/BackdropLoader';
import useSignUp from './useSignUp';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const { paper, form, submit } = useStyles();
  const {
    name,
    nameFieldRef,
    email,
    emailFieldRef,
    password,
    passwordFieldRef,
    repassword,
    repasswordFieldRef,
    repasswordMatch,
    handleInputChange,
    handleSubmit,
    loading,
    error,
    userCreated,
  } = useSignUp();

  if (error) return <ErrorComponent>{error}</ErrorComponent>;

  if (userCreated)
    return (
      <Container>
        <Typography variant='h2' color='primary'>
          An confirmation email was sent to you inbox
        </Typography>
        <Typography color='textPrimary'>
          Please validate your email and then sign in
        </Typography>
        <Button
          component={RouterLink}
          to='/signin'
          color='primary'
          variant='contained'
        >
          sign in
        </Button>
      </Container>
    );

  return (
    <>
      <Helmet>
        <title>Sign Up | Kimys'classroom</title>
      </Helmet>
      <LoaderBackdrop open={loading} />
      <Container component='main' maxWidth='xs'>
        <div className={paper}>
          <Typography component='h1' variant='h5'>
            Register
          </Typography>
          <form className={form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='name'
              label='Full Name'
              name='name'
              autoComplete='name'
              autoFocus
              inputRef={nameFieldRef}
              value={name}
              onChange={handleInputChange}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              inputRef={emailFieldRef}
              value={email}
              onChange={handleInputChange}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              inputRef={passwordFieldRef}
              value={password}
              onChange={handleInputChange}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='repassword'
              type='password'
              id='repassword'
              inputRef={repasswordFieldRef}
              value={repassword}
              onChange={handleInputChange}
              label={`Repeat Password${
                repasswordMatch === undefined
                  ? ''
                  : repasswordMatch
                  ? ''
                  : ` - It doesn't match`
              }`}
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={submit}
            >
              Sign Up
            </Button>
            <Grid container justify='flex-end'>
              <Grid item>
                <Link component={RouterLink} to='/signin' variant='body2'>
                  Already registered? Sign In
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
