import firebase from 'firebase/app';
import 'firebase/auth';

var config = {
  apiKey: 'AIzaSyB7mQfi8Y64vOHYcFQ0TknTx9vOMmQdEYc',
  authDomain: 'kimy-teacher-backend.firebaseapp.com',
  databaseURL: 'https://kimy-teacher-backend.firebaseio.com',
  projectId: 'kimy-teacher-backend',
  storageBucket: 'kimy-teacher-backend.appspot.com',
  messagingSenderId: '36543293221',
  appId: '1:36543293221:web:8ca6be30786f3687455ccc',
};

firebase.initializeApp(config);

export const auth = firebase.auth();
