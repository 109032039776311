import {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { auth } from '../settings/firebase';
import { User } from '../types/User';

type ContextType = Partial<ReturnType<typeof useSession>>;
const SessionContext = createContext<ContextType>({});
export default SessionContext;

export function SessionProvider({ children }: { children: ReactNode }) {
  const contextValue = useSession();
  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
}

function useSession() {
  const [user, setUser] = useState<User | null>();
  const unsubscribeRef = useRef<Function>();

  useEffect(() => subscribe(), []);

  return useMemo(
    () => ({
      user: user ? { ...user, getToken } : user,
      service: {
        subscribe,
        unsubscribeRef: unsubscribeRef,
      },
    }),
    [user]
  );

  function subscribe() {
    setUser(undefined);
    if (unsubscribeRef.current) unsubscribeRef.current();

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const condicion = auth?.currentUser?.providerData[0]?.providerId === 'facebook.com' ? !user?.email : !user?.emailVerified  
      if (condicion) {
        await auth.signOut();
        return setUser(null);
      }

      setUser({
        email: user?.email!,
        name: user?.displayName || 'no-name',
      });
    });

    unsubscribeRef.current = () => {
      unsubscribe();
      unsubscribeRef.current = undefined;
    };
  }

  function getToken() {
    return auth.currentUser!.getIdToken();
  }
}
