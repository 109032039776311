import { useCallback, useEffect, useState } from 'react';
import useAsyncProcess from '../../hooks/useAsync';
import useCustomFetch from '../../hooks/useCustomFetch';
import { Serialized } from '../../types/shared';
import { PackWithOrder, PendingOrder } from './types';
import {
  GET_PACKS_HISTORY,
  GET_PENDING_ORDERS,
} from './../../settings/apiEndpoints';

export default function usePacksHistory() {
  const customFetch = useCustomFetch();
  const { loading, error, start, end } = useAsyncProcess(true);
  const [packsWithOrder, setPacksWithOrder] = useState<PackWithOrder[]>([]);
  const [pendingOrders, setPendingOrders] = useState<PendingOrder[]>([]);

  const fetchPacksWithOrders = useCallback(async () => {
    try {
      const res = await customFetch(GET_PACKS_HISTORY);
      if (!res?.ok) throw new Error(await res?.text());
      const data = (await res.json()).data;
      setPacksWithOrder(deserializePacks(data));
      return true;
    } catch (error) {
      end(error);
      return false;
    }
  }, [customFetch, end]);

  const fetchPendingOrders = useCallback(async () => {
    try {
      const res = await customFetch(GET_PENDING_ORDERS);
      if (!res?.ok) throw new Error(await res?.text());
      const data = (await res.json()).data;
      setPendingOrders(deserializeOrders(data));
      return true;
    } catch (error) {
      end(error);
      return false;
    }
  }, [customFetch, end]);

  const fetchData = useCallback(async () => {
    start();
    if ((await fetchPacksWithOrders()) && (await fetchPendingOrders())) end();
  }, [fetchPacksWithOrders, fetchPendingOrders, start, end]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { packsWithOrder, pendingOrders, loading, error };
}

function deserializeOrders(data: Serialized<PendingOrder[]>) {
  return data.map(({ order, pack }) => ({
    order: {
      ...order,
      date: new Date(order.date),
    },
    pack,
  }));
}

function deserializePacks(data: Serialized<PackWithOrder[]>) {
  return data.map(({ pack, order }) => ({
    pack: {
      ...pack,
      purchaseDate: new Date(pack.purchaseDate),
      endDate: new Date(pack.endDate),
    },
    order: order
      ? {
          ...order,
          date: new Date(order.date),
        }
      : undefined,
  }));
}
