import {
  Container,
  Grid,
  Box,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import ErrorComponent from '../shared/ErrorComponent/ErrorComponent';
import Pack from './Pack';
import Order from './Order';
import usePacksHistory from './usePacksHistory';
import { PackWithOrder, PendingOrder } from './types';
import { Helmet } from 'react-helmet';

function PendingOrders({ pendingOrders }: { pendingOrders: PendingOrder[] }) {
  return (
    <Container maxWidth='md'>
      <Box paddingY={3}>
        <Typography variant='h2' gutterBottom color='primary'>
          Pending Orders
        </Typography>
        <Grid container spacing={1}>
          {pendingOrders.map((pending) => (
            <Grid item xs={12} key={pending.order.id}>
              <Order pendingOrder={pending} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}

function PacksHistory({ packsWithOrder }: { packsWithOrder: PackWithOrder[] }) {
  return (
    <Container maxWidth='md'>
      <Box paddingY={3}>
        <Typography variant='h2' gutterBottom color='primary'>
          Packs History
        </Typography>
        {packsWithOrder.length ? (
          <Grid container spacing={1}>
            {packsWithOrder.map((packWithOrder) => (
              <Grid item xs={12} key={packWithOrder.pack.id}>
                <Pack packWithOrder={packWithOrder} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>You dont have any register yet</Typography>
        )}
      </Box>
    </Container>
  );
}

export default function PendingOrdersAndPacksHistoryWrapper() {
  const { packsWithOrder, pendingOrders, loading, error } = usePacksHistory();

  if (error) return <ErrorComponent>{error}</ErrorComponent>;
  if (loading) return <LinearProgress />;

  return (
    <>
      <Helmet>
        <title>Packs history | Kimys'classroom</title>
      </Helmet>
      {!!pendingOrders.length && (
        <PendingOrders pendingOrders={pendingOrders} />
      )}
      <PacksHistory packsWithOrder={packsWithOrder} />
    </>
  );
}
