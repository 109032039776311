import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { Order } from '../../types/Order';
import { PackWithOrder } from './types';

function PaymentInformation({ order }: { order: Order }) {
  const { total, pspInfo } = order;
  const { provider, orderId } = pspInfo;
  return (
    <>
      <Typography>Payment method {provider}</Typography>
      <Typography gutterBottom>Order ID {orderId}</Typography>
      <Typography color='textSecondary'>Total {total}$</Typography>
    </>
  );
}

function Pack({ packWithOrder }: { packWithOrder: PackWithOrder }) {
  const { pack, order } = packWithOrder;
  const { id, purchaseDate, endDate, classesLeft, description } = pack;

  const purchaseDateFormatted = format(purchaseDate, `LLLL do 'at' hh:mm aaa`);
  const endDateFormatted = format(endDate, `LLLL do 'at' hh:mm aaa`);

  return (
    <Card variant='outlined'>
      <CardContent>
        <Typography color='textSecondary' gutterBottom>
          {description}
        </Typography>
        <Typography>Purchase date {purchaseDateFormatted}</Typography>
        <Typography>End date {endDateFormatted}</Typography>
        <Typography color='textSecondary' gutterBottom>
          Classes left {classesLeft}
        </Typography>
        {order && <PaymentInformation order={order} />}
      </CardContent>
      <Box width='fit-content' marginLeft='auto' marginRight={2}>
        <Typography variant='overline' color='textSecondary'>
          ID: {id}
        </Typography>
      </Box>
    </Card>
  );
}

export default Pack;
