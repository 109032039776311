import { useCallback, useContext, useEffect, useRef } from 'react';
import SessionContext from '../contexts/SessionContext';

export default function useCustomFetch(withToken = true) {
  const controllerRef = useRef(new AbortController());
  const { user } = useContext(SessionContext);

  useEffect(() => () => controllerRef.current.abort(), []);

  const customFetch = useCallback(
    async (...params: Parameters<typeof fetch>) => {
      const [input, originalInit] = params;

      const customInit: RequestInit = {
        signal: controllerRef.current.signal,
        ...originalInit,
      };

      if (withToken && user)
        customInit.headers = {
          Authorization: `Bearer ${await user.getToken()}`,
          ...customInit.headers,
        };

      try {
        return await fetch(input, customInit);
      } catch (error) {
        if (error.name === 'AbortError') return undefined;
        throw error;
      }
    },
    [withToken, user]
  );

  return customFetch;
}
