import {
  AppBar,
  Grid,
  Slide,
  Toolbar,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from '@material-ui/core';
import Logo from './Logo/Logo';
import Navbar from '../Navbar/Navbar';

function Header() {
  const theme = useTheme();
  const trigger = useScrollTrigger();
  const navOnTop = useMediaQuery(theme.breakpoints.up(theme.navbar.breakpoint));

  return (
    <>
      <Slide appear={false} direction='down' in={!trigger}>
        <AppBar color='secondary'>
          <Toolbar>
            <Grid
              container
              alignItems='center'
              alignContent='center'
              justify={navOnTop ? 'space-between' : 'center'}
            >
              <Grid item>
                <Logo />
              </Grid>
              {navOnTop && (
                <Grid item>
                  <Navbar onTop={navOnTop} />
                </Grid>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
      </Slide>
      <Toolbar />
      {!navOnTop && <Navbar />}
    </>
  );
}

export default Header;
