import { Card, CardContent, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { PendingOrder } from './types';

function Order({ pendingOrder }: Props) {
  const { order, pack } = pendingOrder;
  const { name } = pack;
  const { date, total, pspInfo } = order;
  const { provider, orderId } = pspInfo;

  const dateFormatted = format(date, `LLLL do 'at' hh:mm aaa`);

  return (
    <Card variant='outlined'>
      <CardContent>
        <Typography color='textSecondary' gutterBottom>
          {name}
        </Typography>
        <Typography>Order place on {dateFormatted}</Typography>
        <Typography>Payment method {provider}</Typography>
        <Typography gutterBottom>Order ID {orderId}</Typography>
        <Typography color='textSecondary'>Total {total}$</Typography>
      </CardContent>
    </Card>
  );
}

type Props = {
  pendingOrder: PendingOrder;
};

export default Order;
