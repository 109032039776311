import { PackInformation } from './../../types/PackOffer';
import { useCallback, useEffect, useState } from 'react';
import useAsyncProcess from '../../hooks/useAsync';
import useCustomFetch from '../../hooks/useCustomFetch';
import { GET_PACKS_OFFER } from '../../settings/apiEndpoints';

export default function usePacksOffer() {
  const [packsOffer, setPacksOffer] = useState<PackInformation[]>([]);
  const { loading, error, start, end } = useAsyncProcess(true);
  const customFetch = useCustomFetch();

  const fetchPacksOffer = useCallback(async () => {
    start();
    try {
      const res = await customFetch(GET_PACKS_OFFER);
      if (!res?.ok) throw new Error(await res?.text());
      const data = (await res.json()).data;
      setPacksOffer(data);
      end();
    } catch (error) {
      end(error);
    }
  }, [customFetch, start, end]);

  useEffect(() => {
    fetchPacksOffer();
  }, [fetchPacksOffer]);

  return { packsOffer, loading, error };
}
