import { makeStyles, Typography, Box, Link } from '@material-ui/core';
import { format, differenceInMinutes, isFuture } from 'date-fns';
import { formatDistanceToNow } from 'date-fns/esm';
import { Appointment } from '../../types/Appointment';
import TripleDotButton from '../shared/TripleDotButton/TripleDotButton';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      border: '1px solid',
      borderRight: '5px solid',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
      transition: '0.3s',
      borderColor: ({ closest }: Props) =>
        closest ? theme.palette.primary.light : theme.palette.secondary.light,
      position: 'relative',
      '&::after': {
        content: '""',
        display: 'block',
        paddingBottom: '25%',
      },
      '& strong': {
        color: ({ closest }: Props) =>
          closest ? theme.palette.primary.main : theme.palette.secondary.main,
      },
      '&:hover': {
        boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
      },
    },
    dateContainer: {
      backgroundColor: ({ closest }: Props) =>
        closest ? theme.palette.primary.light : theme.palette.secondary.light,
      color: ({ closest }: Props) =>
        closest
          ? theme.palette.primary.contrastText
          : theme.palette.secondary.contrastText,
      position: 'absolute',
      width: '30%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      '& .day': { fontSize: '1.5rem', fontWeight: 'bold' },
      '& .month': { marginTop: '-5px' },
    },
    descriptionContainer: {
      position: 'absolute',
      width: '70%',
      height: '100%',
      left: '30%',
      paddingLeft: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',

      '& .action': {
        position: 'absolute',
        top: '0px',
        right: '0px',
        padding: '.1rem .3rem',
        fontWeight: 'bold',
        color: ({ closest }: Props) =>
          closest ? theme.palette.primary.main : theme.palette.secondary.main,
      },

      '& .button': {
        right: '-5px',
        padding: 0,
      },

      '& p': {
        fontSize: '0.9rem',
        lineHeight: '1.3',
      },
    },
  };
});

function Lesson(props: Props) {
  const { root, dateContainer, descriptionContainer } = useStyles(props);
  const { date, endDate, meeting } = props.appointment;

  return (
    <div className={root}>
      <div className={dateContainer}>
        <div>
          <Typography className='day'>{format(date, 'do')}</Typography>
          <Typography className='month'>{format(date, 'LLLL')}</Typography>
        </div>
      </div>
      <div className={descriptionContainer}>
        <Typography>{format(date, `'At 'h:mm bbb`)}</Typography>
        <Typography>
          Duration: {differenceInMinutes(endDate, date)} minutes
        </Typography>
        {isFuture(date) ? (
          <Typography noWrap>
            Starting in <strong>{formatDistanceToNow(date)}</strong>
          </Typography>
        ) : (
          <Typography noWrap>
            Started <strong>{formatDistanceToNow(date)}</strong> ago
          </Typography>
        )}
        {meeting ? (
          <Link className='action' href={meeting.joinUrl}>
            JOIN CLASS
          </Link>
        ) : (
          <Box className='action button'>
            <TripleDotButton options={[['Cancel', props.cancelAppointment]]} />
          </Box>
        )}
      </div>
    </div>
  );
}

type Props = {
  appointment: Appointment;
  closest: boolean;
  cancelAppointment(): void;
};

export default Lesson;
