import { isAfter, isEqual, format } from 'date-fns';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import AgendaContext from '../../../contexts/AgendaContext';
import useRequireAuth from '../../../hooks/useRequireAuth';
import { AgendaItem } from '../../../types/Agenda';

export default function useDayAgenda() {
  const {
    selectedDate,
    agenda,
    selectedAgendaItem,
    setSelectedAgendaItem,
    activePacks,
    bookAppointment,
  } = useContext(AgendaContext);
  const [showDialog, setShowDialog] = useState(false);
  const requireAuth = useRequireAuth();
  const history = useHistory();

  const agendaItems = agenda![selectedDate!.valueOf()] || [];
  const dialogMessage = makeDialogMessage();

  return {
    agendaItems,
    isSelected,
    handleAppointmentBooking,
    showDialog,
    dialogMessage,
    acceptDialogHandler,
    rejectDialogHandler,
    selectedAgendaItem,
    selectedDate: selectedDate!,
    setSelectedAgendaItem: setSelectedAgendaItem!,
  };

  function isSelected({ date }: AgendaItem) {
    return !!(selectedAgendaItem && isEqual(selectedAgendaItem.date, date));
  }

  function handleAppointmentBooking() {
    if (requireAuth()) return;
    const candidates = activePacks!.filter((pack) =>
      isAfter(pack.endDate, selectedAgendaItem!.date)
    );
    if (!candidates.length) return setShowDialog(true);
    bookAppointment!();
  }

  function acceptDialogHandler() {
    history.push('/packs');
  }

  function rejectDialogHandler() {
    setShowDialog(false);
  }

  function makeDialogMessage() {
    if (!selectedAgendaItem) return '';

    const packs = activePacks!;
    const dateToBook = selectedAgendaItem.date;
    const dateTemplate = `LLLL do 'at' hh:mm aaa`;

    switch (packs.length) {
      case 0:
        return 'You dont have any active pack, please feel free to buy one';
      case 1:
        return `Your currently active pack ends on ${format(
          packs[0].endDate,
          dateTemplate
        )}\nIt isn't valid to book an apoointment on ${format(
          dateToBook,
          dateTemplate
        )}\nFeel free to buy a new pack or try to book on a valid date`;
      default:
        return `Your currently further to expire active pack ends on ${format(
          packs[packs.length - 1].endDate,
          dateTemplate
        )}\nIt isn't valid to book an apoointment on ${format(
          dateToBook,
          dateTemplate
        )}\nFeel free to buy a new pack or try to book on a valid date`;
    }
  }
}
