import {
  Container,
  Grid,
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import LoaderBackdrop from '../shared/BackdropLoader/BackdropLoader';
import CoinpaymentsButton from './CoinpaymentsButton';
import PaypalButton from './PaypalButton';
import useCheckout from './useCheckout';
import ProcessDone from './ProcessDone';
import ErrorComponent from '../shared/ErrorComponent/ErrorComponent';
import TermsAndConditions from './TermsAndConditions';
import { withAuthRequired } from '../shared/hoc/withAuthRequired';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  boldText: {
    fontWeight: 'bold',
  },
}));

function Checkout() {
  const { boldText } = useStyles();
  const checkout = useCheckout();

  const { pack, error, order, termsAccepted } = checkout;

  if (!pack || termsAccepted === false) return <Redirect to='packs' />;

  if (error) return <ErrorComponent>{error}</ErrorComponent>;
  if (order && order.status !== 'CREATED')
    return <ProcessDone order={order} packName={pack.name} />;

  const {
    loading,
    buttonLoadedCallback,
    createPaypalOrder,
    capturePaypalOrder,
    createCoinpaymentsOrder,
    handleAccept,
    handleReject,
  } = checkout;
  const { name, description, classesQuantity, daysDuration, price } = pack;
  return (
    <>
      <Helmet>
        <title>Checkout | Kimys'classroom</title>
      </Helmet>
      <LoaderBackdrop open={loading} />
      <TermsAndConditions
        open={termsAccepted === undefined}
        handleAccept={handleAccept}
        handleReject={handleReject}
      />
      ;
      <Box marginTop={6}>
        <Container maxWidth='sm' component='main'>
          <Typography
            className={boldText}
            variant='h4'
            component='h1'
            align='center'
            color='primary'
            gutterBottom
          >
            Complete your purchase
          </Typography>
          <Box marginY={4}>
            <Typography className={boldText} gutterBottom color='secondary'>
              {name} pack
            </Typography>
            <Box component='ul' paddingLeft={2}>
              <Typography component='li'>{description}</Typography>
              <Typography component='li'>{classesQuantity} lessons</Typography>
              <Typography component='li'>
                {daysDuration} days to consume it
              </Typography>
            </Box>
            <Typography className={boldText} color='secondary' align='right'>
              Total {price.toFixed(2)}$
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <PaypalButton
                successfullyLoaded={buttonLoadedCallback}
                errorOnLoading={buttonLoadedCallback}
                onCancel={buttonLoadedCallback}
                createOrder={createPaypalOrder}
                onApprove={capturePaypalOrder as any}
                onError={buttonLoadedCallback}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CoinpaymentsButton onClick={createCoinpaymentsOrder} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default withAuthRequired(Checkout);
