import { Box, Grid, Hidden, Button, Typography } from '@material-ui/core';
import { ReactComponent as WhoIsKimyImage } from '../../assets/home/who-is-kimy.svg';
import { Link as RouterLink } from 'react-router-dom';

function WhoIsKimy() {
  return (
    <Box padding={3}>
      <Grid container>
        <Hidden xsDown>
          <Grid item sm={4}>
            <Box
              style={{
                maxHeight: '450px',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <WhoIsKimyImage width='100%' height='100%' />
            </Box>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={8}>
          <Typography color='primary' variant='h1' align='center' gutterBottom>
            Who's Kimy?
          </Typography>
          <Typography align='center' paragraph>
            ¡Hola!
          </Typography>
          <Typography align='center' paragraph>
            Are you traveling to Machu Pichu?
            <br />
            Do you have new clients from Argentina?
            <br />
            Is your partner's family from México?
            <br />
            Don't worry. You will be speaking to them in{' '}
            <strong>short term</strong>.
          </Typography>
          <Typography align='justify' paragraph>
            I'm Kim. I have more than 3 years working as a language instructor
            at different centers. I've been teaching <strong>Spanish</strong>{' '}
            and English to students from all over the world (United States,
            Canada, England, ...) I have experience working on{' '}
            <strong>one-on-one</strong> lessons with all ages. My techniques and
            lessons (based on real-life situations) give students the confidence
            to hold conversations with Spanish speakers and to achieve their
            dreams.
          </Typography>
          <Typography align='center' paragraph>
            My classes are fun, enjoyable, and{' '}
            <strong>focused on your needs</strong>!
          </Typography>
          <Box m='auto' width='fit-content'>
            <Button
              color='primary'
              variant='contained'
              component={RouterLink}
              to='/agenda'
            >
              book now
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default WhoIsKimy;
