import {
  Box,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import PackInformationCard from './PackInformationCard';
import usePacksOffer from './usePacksOffer';
import ErrorComponent from '../shared/ErrorComponent/ErrorComponent';
import { PackInformation } from '../../types/PackOffer';
import { Helmet } from 'react-helmet';

function PacksOffer({ packsOffer }: Props) {
  return (
    <>
      <Box paddingY={4}>
        <Container maxWidth='md' component='main'>
          <Typography
            variant='h2'
            align='center'
            color='primary'
            gutterBottom
            style={{ fontSize: '2.5rem' }}
          >
            Pricing
          </Typography>
          <Typography
            variant='h5'
            align='center'
            color='textSecondary'
            component='p'
          >
            Choose the package that best suits your needs
          </Typography>
        </Container>
      </Box>
      <Container maxWidth='lg' component='main'>
        <Grid container spacing={3} alignItems='center' justify='center'>
          {packsOffer.map((pack, index) => (
            <Grid item key={pack.name} xs={12} sm={index === 2 ? 12 : 6} md={4}>
              <PackInformationCard pack={pack} mostPopular={false} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}

type Props = {
  packsOffer: PackInformation[];
};

export default function PacksOfferWrapper() {
  const { packsOffer, loading, error } = usePacksOffer();

  if (error) return <ErrorComponent>{error}</ErrorComponent>;

  if (loading) return <LinearProgress />;

  return (
    <>
      <Helmet>
        <title>Pricing | Kimys'classroom</title>
      </Helmet>
      <PacksOffer packsOffer={packsOffer} />
    </>
  );
}
