import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { isBefore, startOfDay } from 'date-fns';
import useAsyncProcess from '../hooks/useAsync';
import useCustomFetch from '../hooks/useCustomFetch';

import { Agenda, AgendaItem } from '../types/Agenda';
import { Serialized } from '../types/shared';

import { GET_AGENDA, BOOK_APPOINTMENT } from '../settings/apiEndpoints';
import { Pack } from '../types/Pack';
import useRequireAuth from '../hooks/useRequireAuth';

type ContextType = Partial<ReturnType<typeof useAgenda>>;
const AgendaContext = createContext<ContextType>({});
export default AgendaContext;

export function AgendaContextProvider({ children }: { children: ReactNode }) {
  const contextValue = useAgenda();
  return (
    <AgendaContext.Provider value={contextValue}>
      {children}
    </AgendaContext.Provider>
  );
}

function useAgenda() {
  const requireAuth = useRequireAuth();
  const customFetch = useCustomFetch();
  const { loading, error, start, end } = useAsyncProcess(true);
  const [activePacks, setActivePacks] = useState<Pack[]>([]);
  const [agenda, setAgenda] = useState<Agenda>({});

  const [selectedDate, setSelectedDate] = useState<Date>();
  const [selectedAgendaItem, setSelectedAgendaItem] = useState<AgendaItem>();

  const [bookingResponse, setBookingResponse] = useState<[boolean, string]>();
  const timeoutMessageResponseRef = useRef<number>();

  const fetchAgenda = useCallback(async () => {
    start();
    try {
      const res = await customFetch(GET_AGENDA);
      if (!res) return;
      if (!res.ok) throw new Error(await res.text());
      const data = (await res.json()).data;
      const dataNormalized = dataNormalizer(data);
      setAgenda(dataNormalized.agenda);
      setActivePacks(dataNormalized.activePacks);
      setSelectedAgendaItem(undefined);
      end();
    } catch (error) {
      end(error);
    }
  }, [customFetch, end, start, setAgenda]);

  const bookAppointment = useCallback(async () => {
    if (requireAuth()) return;
    start();
    try {
      const res = await customFetch(BOOK_APPOINTMENT, {
        method: 'POST',
        body: JSON.stringify({ date: selectedAgendaItem?.date }),
        headers: { 'Content-Type': 'application/json' },
      });
      if (res?.ok) updateBookingResponse(true, 'Booked successfully');
      else throw new Error(await res?.text());
    } catch (error) {
      updateBookingResponse(false, error.message);
    } finally {
      fetchAgenda();
    }
  }, [customFetch, start, selectedAgendaItem, fetchAgenda, requireAuth]);

  //set the most next agenda date as selected date if current selected date is undefined or less than min date
  useEffect(() => {
    const agendaDates = Object.keys(agenda);
    if (agendaDates.length) {
      const mostNextAgendaDate = new Date(parseInt(agendaDates[0]));
      if (!selectedDate || isBefore(selectedDate, mostNextAgendaDate))
        setSelectedDate(mostNextAgendaDate);
    }
  }, [agenda, selectedDate, setSelectedDate]);

  //set agendaItem as undefined when selected date change
  useEffect(() => {
    setSelectedAgendaItem(undefined);
  }, [selectedDate, setSelectedAgendaItem]);

  return {
    loading,
    error,
    activePacks,
    agenda,
    selectedDate,
    selectedAgendaItem,
    bookingResponse,
    setSelectedDate,
    setSelectedAgendaItem,
    fetchAgenda,
    bookAppointment,
  };

  function updateBookingResponse(successfully: boolean, message?: string) {
    if (successfully) setBookingResponse([true, 'Booked successfully. The link to join the lesson will be sent to your e-mail 1 hour before the scheduled start time.']);
    else setBookingResponse([false, message || 'Error']);

    clearTimeout(timeoutMessageResponseRef.current);
    timeoutMessageResponseRef.current = window.setTimeout(
      () => setBookingResponse(undefined),
      2000
    );
  }
}

type Data = Serialized<{
  appointments: AgendaItem[];
  activePacks: Pack[];
}>;

function dataNormalizer(data: Data) {
  const activePacks = data.activePacks.map<Pack>((pack) => ({
    ...pack,
    purchaseDate: new Date(pack.purchaseDate),
    endDate: new Date(pack.endDate),
  }));

  const agenda = data.appointments.reduce<Agenda>((agenda, item) => {
    const agendaItem: AgendaItem = {
      ...item,
      date: new Date(item.date),
      endDate: new Date(item.endDate),
    };
    const dateAsNum = startOfDay(agendaItem.date).valueOf();
    if (!agenda[dateAsNum]) agenda[dateAsNum] = [];
    agenda[dateAsNum].push(agendaItem);
    return agenda;
  }, {});

  return { activePacks, agenda };
}
