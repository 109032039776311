import {
  Container,
  Grid,
  Box,
  Button,
  Divider,
  List,
  ListItem as ListItemMui,
  ListItemIcon,
  ListItemText,
  ListItemProps,
  Paper,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Link as RouterLink, Switch, Route } from 'react-router-dom';
import { auth } from '../../settings/firebase';
import { withAuthRequired } from '../shared/hoc/withAuthRequired';
import { FiBookOpen, FiPackage } from 'react-icons/fi';
import LessonsHistory from '../LessonsHistory/LessonsHistory';
import PacksHistory from '../PacksHistory/PacksHistory';
import { Helmet } from 'react-helmet';

function ListItem(props: ListItemProps<RouterLink>) {
  return <ListItemMui component={RouterLink} {...props} button />;
}

function Account() {
  const primaryColor = useTheme().palette.primary.light;

  return (
    <Container maxWidth='sm'>
      <Box marginTop={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h5' gutterBottom color='textSecondary'>
              History
            </Typography>
            <Paper>
              <List>
                <ListItem to='/account/packs-history'>
                  <ListItemIcon>
                    <FiPackage size={25} color={primaryColor} />
                  </ListItemIcon>
                  <ListItemText primary='Packs' />
                </ListItem>
                <Divider />
                <ListItem to='/account/lessons-history'>
                  <ListItemIcon>
                    <FiBookOpen size={25} color={primaryColor} />
                  </ListItemIcon>
                  <ListItemText primary='Lessons' />
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => auth.signOut()}
              fullWidth
              variant='contained'
              color='secondary'
            >
              Sign out
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

function AccountWrapper() {
  return (
    <>
      <Helmet>
        <title>Manage Account | Kimys'classroom</title>
      </Helmet>
      <Switch>
        <Route path='/account/lessons-history' component={LessonsHistory} />
        <Route path='/account/packs-history' component={PacksHistory} />
        <Route component={Account} />
      </Switch>
    </>
  );
}

export default withAuthRequired(AccountWrapper);
