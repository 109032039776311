import {
  ShoppingCartOutlined,
  EventAvailableRounded,
  ScheduleRounded,
  PersonOutlineRounded,
} from '@material-ui/icons';

const routes = [
  {
    name: 'Packs',
    icon: ShoppingCartOutlined,
    path: '/packs',
  },
  {
    name: 'Agenda',
    icon: EventAvailableRounded,
    path: '/agenda',
  },
  {
    name: 'Schedule',
    icon: ScheduleRounded,
    path: '/schedule',
  },
  {
    name: 'Account',
    icon: PersonOutlineRounded,
    path: '/account',
  },
  {
    name: 'Sign in',
    icon: PersonOutlineRounded,
    path: '/signin',
  },
];

export default routes;
