import { Grid } from '@material-ui/core';
import OnlineSpanish from './OnlineSpanish';
import Features from './Features';
import WhoIsKimy from './WhoIsKimy';
import HowItWorks from './HowItWorks';
import Testimonials from './testimonials';
import ContactMe from './ContactMe';
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <>
      <Helmet>
        <title>Home | Kimys'classroom</title>
      </Helmet>
      <Grid container>
        <Grid item xs={12}>
          <OnlineSpanish />
        </Grid>
        <Grid item xs={12}>
          <Features />
        </Grid>
        <Grid item xs={12}>
          <WhoIsKimy />
        </Grid>
        <Grid item xs={12}>
          <HowItWorks />
        </Grid>
        <Grid item xs={12}>
          <Testimonials />
        </Grid>
        <Grid item xs={12}>
          <ContactMe />
        </Grid>
      </Grid>
    </>
  );
}

export default Home;
