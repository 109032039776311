import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { format, differenceInMinutes } from 'date-fns';
import { Appointment, AppointmentStatus } from '../../types/Appointment';

function Lesson({ lesson }: Props) {
  const { id, date, endDate, packId, status } = lesson;

  const dateFormatted = format(date, `LLLL do 'at' hh:mm aaa`);
  const duration = differenceInMinutes(endDate, date);

  return (
    <Card variant='outlined'>
      <CardContent>
        <Typography>{dateFormatted}</Typography>
        <Typography color='textSecondary' gutterBottom>
          {duration} minutes
        </Typography>
        {status !== AppointmentStatus.Default && (
          <Typography color='primary' gutterBottom>
            Cancelled by{' '}
            {status === AppointmentStatus.CancelledByAdmin
              ? 'Kimy'
              : 'yourself'}
          </Typography>
        )}
        <Typography variant='body2' component='p'>
          From pack <br />
          {packId.toUpperCase()}
        </Typography>
      </CardContent>
      <Box width='fit-content' marginLeft='auto' marginRight={2}>
        <Typography variant='overline' color='textSecondary'>
          ID: {id}
        </Typography>
      </Box>
    </Card>
  );
}

type Props = {
  lesson: Appointment;
};

export default Lesson;
