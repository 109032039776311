import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '&::after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%',
    },
  },
  innerBorder: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
    height: '80%',

    cursor: ({ capacity: [, total] }: Props) => (total ? 'pointer' : 'unset'),

    opacity: ({ capacity: [, total] }: Props) => (total ? 'unset' : '0.5'),

    backgroundColor: ({ selected }: Props) =>
      selected ? theme.palette.secondary.main : 'inherit',

    color: ({ selected }: Props) =>
      selected ? theme.palette.secondary.contrastText : 'inherit',
  },
}));

function CalendarDay(props: Props) {
  const { root, innerBorder } = useStyles(props);

  var day = props.date.getDate().toString();
  if (day.length < 2) day = '0' + day;

  return (
    <div className={root}>
      <div className={innerBorder} onClick={onClick}>
        <Typography variant='body1'>{day}</Typography>
      </div>
    </div>
  );

  function onClick() {
    const { capacity, handleSelectedDateChange } = props;
    if (capacity[1]) handleSelectedDateChange();
  }
}

type Props = {
  date: Date;
  selected?: boolean;
  capacity: [number, number];
  handleSelectedDateChange(): void;
};

export default CalendarDay;
