import { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { startOfMonth, getDaysInMonth, setDate, isEqual } from 'date-fns';
import AgendaContext from '../../../contexts/AgendaContext';
import CalendarDay from './CalendarDay';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
  },
}));

function CalendarGrid() {
  const { root } = useStyles();
  const contextValue = useContext(AgendaContext);
  const { selectedDate, agenda, setSelectedDate } = contextValue as Required<
    typeof contextValue
  >;

  return (
    <div className={root}>
      {previousMonthSpaces()}
      {currentMonth()}
    </div>
  );

  function previousMonthSpaces() {
    return new Array(startOfMonth(selectedDate).getDay())
      .fill(null)
      .map((_, day) => <div key={`prev-month-${day}`} />);
  }

  function currentMonth() {
    return new Array(getDaysInMonth(selectedDate)).fill(null).map((_, day) => {
      const date = setDate(selectedDate, day + 1);
      const agendaItems = agenda[date.valueOf()] || [];
      const unbooked = agendaItems.reduce(
        (accum, item) => (item.bookedBy === 'none' ? accum + 1 : accum),
        0
      );
      return (
        <CalendarDay
          key={day}
          date={date}
          capacity={[unbooked, agendaItems.length]}
          selected={isEqual(date, selectedDate)}
          handleSelectedDateChange={() => setSelectedDate(date)}
        />
      );
    });
  }
}

export default CalendarGrid;
