import ErrorComponent from '../shared/ErrorComponent/ErrorComponent';
import LoaderBackdrop from '../shared/BackdropLoader/BackdropLoader';
import ConfirmationEmailSent from './ConfirmationEmailSent';
import EmailNotVerified from './EmailNotVerified';
import UserCredentials from './Form';
import useSignIn from './useSignIn';
import { Helmet } from 'react-helmet';

export default function SignIn() {
  const {
    email,
    emailFieldRef,
    password,
    passwordFieldRef,
    handleInputChange,
    handleSubmit,
    error,
    loading,
    emailNotVerified,
    confirmationEmailSent,
    sendConfirmationEmail,
    tryAgain,
    signInWithGoogle,
    signInWithFacebook,
  } = useSignIn();

  if (error) return <ErrorComponent children={error} />;

  if (confirmationEmailSent)
    return <ConfirmationEmailSent tryAgain={tryAgain} />;

  if (emailNotVerified)
    return <EmailNotVerified sendConfirmationEmail={sendConfirmationEmail} />;

  return (
    <>
      <Helmet>
        <title>Sign In | Kimys'classroom</title>
      </Helmet>

      <LoaderBackdrop open={loading} />
      <UserCredentials
        email={email}
        emailRef={emailFieldRef}
        password={password}
        passwordRef={passwordFieldRef}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        signInWithGoogle={signInWithGoogle}
        signInWithFacebook={signInWithFacebook}
      />
    </>
  );
}
