export enum AppointmentStatus {
  Default,
  CancelledByUser,
  CancelledByAdmin,
}

export type Appointment = {
  id: string;
  userId: string;
  date: Date;
  endDate: Date;
  packId: string;
  status: AppointmentStatus;
  meeting?: Meeting;
};

type Meeting = {
  id: number;
  password: string;
  joinUrl: string;
};
