import { Serialized } from './../../types/shared';
import { useCallback, useEffect, useState } from 'react';
import useAsyncProcess from '../../hooks/useAsync';
import useCustomFetch from '../../hooks/useCustomFetch';
import { GET_LESSONS_HISTORY } from '../../settings/apiEndpoints';
import { Appointment } from '../../types/Appointment';

export default function useLessonsHistory() {
  const [lessons, setLessons] = useState<Appointment[]>([]);
  const { loading, error, start, end } = useAsyncProcess(true);
  const customFetch = useCustomFetch();

  const fetchLessonsHistory = useCallback(async () => {
    start();
    try {
      const res = await customFetch(GET_LESSONS_HISTORY);
      if (!res?.ok) throw new Error(await res?.text());
      const data = (await res.json()).data;
      setLessons(dataNormalize(data));
      end();
    } catch (error) {
      end(error);
    }
  }, [customFetch, start, end]);

  useEffect(() => {
    fetchLessonsHistory();
  }, [fetchLessonsHistory]);

  return { lessons, loading, error };
}

function dataNormalize(data: Serialized<Appointment[]>): Appointment[] {
  return data
    .map((appointment) => ({
      ...appointment,
      date: new Date(appointment.date),
      endDate: new Date(appointment.endDate),
    }))
    .sort((a, b) => b.date.valueOf() - a.date.valueOf());
}
