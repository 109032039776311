import { makeStyles, Paper } from '@material-ui/core';
import CalendarGrid from './CalendarGrid';
import CalendarHeader from './CalendarHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    userSelect: 'none',
    width: '100%',
  },
}));

function Calendar() {
  const { root } = useStyles();

  return (
    <div className={root}>
      <Paper>
        <CalendarHeader />
        <CalendarGrid />
      </Paper>
    </div>
  );
}

export default Calendar;
