import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import firebase from 'firebase/app';
import { auth } from '../../settings/firebase';
import SessionContext from '../../contexts/SessionContext';
import useAsyncProcess from '../../hooks/useAsync';
import useCustomFetch from '../../hooks/useCustomFetch';
import { NOTIFY_SIGNIN } from './../../settings/apiEndpoints';

export default function useSignIn() {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const { email, password } = credentials;

  const emailFieldRef = useRef<HTMLInputElement>(null);
  const passwordFieldRef = useRef<HTMLInputElement>(null);

  const customFetch = useCustomFetch(false);
  const { loading, error, start, end } = useAsyncProcess();
  const { user, service } = useContext(SessionContext);
  const { subscribe, unsubscribeRef } = service!;

  const [emailNotVerified, setEmailNotVerified] = useState(false);
  const [confirmationEmailSent, setconfirmationEmailSent] = useState(false);

  const history = useHistory();
  const pathFrom =
    useLocation<{ from: Location } | undefined>().state?.from.pathname || '/';

  useEffect(() => {
    if (user) history.replace(pathFrom);
  }, [user, history, pathFrom]);

  return {
    email,
    emailFieldRef,
    password,
    passwordFieldRef,
    handleInputChange,
    handleSubmit,
    loading,
    error,
    emailNotVerified,
    sendConfirmationEmail,
    confirmationEmailSent,
    tryAgain,
    signInWithGoogle,
    signInWithFacebook,
  };

  async function signInWithGoogle() {
    start();
    const provider = new firebase.auth.GoogleAuthProvider();
    unsubscribeRef.current && unsubscribeRef.current();
    try {
      await auth.signInWithPopup(provider);
      await notifySignedIn();
      end();
      subscribe();
    } catch (error) {
      end(error);
    }
  }

  async function signInWithFacebook() {
    start();
    const provider = new firebase.auth.FacebookAuthProvider();
    unsubscribeRef.current && unsubscribeRef.current();
    try {
      await auth.signInWithPopup(provider);
      await notifySignedIn();
      end();
      subscribe();
    } catch (error) {
      end(error);
    }
  }

  async function signIn() {
    start();
    unsubscribeRef.current && unsubscribeRef.current();
    try {
      await auth.signInWithEmailAndPassword(email, password);
      const currentUser = auth.currentUser;
      if (!currentUser) throw new Error('User not found');
      if (currentUser.emailVerified) {
        await notifySignedIn();
        subscribe();
      } else {
        setEmailNotVerified(true);
        end();
      }
    } catch (error) {
      auth.signOut();
      end(error);
    }
  }

  async function notifySignedIn() {
    const resp = await customFetch(NOTIFY_SIGNIN, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await auth.currentUser!.getIdToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ location: await getLocation() }),
    });

    if (!resp?.ok) {
      await auth.signOut();
      throw new Error(await resp?.text());
    }
  }

  async function sendConfirmationEmail() {
    start();
    try {
      await auth.currentUser?.sendEmailVerification();
      await auth.signOut();
      setconfirmationEmailSent(true);
    } catch (error) {
      end(error);
    }
  }

  function tryAgain() {
    subscribe();
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setCredentials((credentials) => ({ ...credentials, [name]: value }));
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    switch (true) {
      case !email:
        emailFieldRef.current?.focus();
        return;
      case !password:
        passwordFieldRef.current?.focus();
        return;
    }

    signIn();
  }

  async function getLocation() {
    try {
      const res = await customFetch('https://extreme-ip-lookup.com/json/');
      if (!res) throw new Error('Error getting location');
      const data = await res.json();
      return {
        continent: data.continent,
        country: data.country,
        region: data.region,
        city: data.city,
        countryCode: data.countryCode,
      };
    } catch (error) {
      return {
        continent: 'unknown',
        country: 'unknown',
        region: 'unknown',
        city: 'unknown',
        countryCode: 'unknown',
      };
    }
  }
}
