import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useAsyncProcess from '../../hooks/useAsync';
import useCustomFetch from '../../hooks/useCustomFetch';
import useRenderedTimes from '../../hooks/useRenderedTimes';

import {
  CREATE_ORDER_PAYPAL,
  CAPTURE_PAYMENT_PAYPAL,
  CREATE_ORDER_COINPAYMENTS,
} from '../../settings/apiEndpoints';
import { Order } from '../../types/Order';
import { PackInformation } from '../../types/PackOffer';
import { Serialized } from '../../types/shared';

export default function useCheckout() {
  const [termsAccepted, setTermsAccepted] = useState<boolean>();
  const { loading, error, start, end } = useAsyncProcess(true);
  const customFetch = useCustomFetch();
  const [order, setOrder] = useState<Order>();
  const renderedTimesRef = useRenderedTimes();

  const pack = useLocation<{ pack: PackInformation } | undefined>().state?.pack;

  function handleAccept() {
    setTermsAccepted(true);
  }

  function handleReject() {
    setTermsAccepted(false);
  }

  async function createCoinpaymentsOrder() {
    start();
    try {
      const res = await customFetch(CREATE_ORDER_COINPAYMENTS + pack?.id, {
        method: 'POST',
      });
      if (!res?.ok) throw new Error(await res?.text());
      const data = (await res!.json()).data;
      const order = dataNormalize(data);
      setOrder(order);
      window.open(
        order.pspInfo.link,
        '',
        'toolbar=no,status=no,menubar=no,location=center,scrollbars=no,resizable=no,height=500,width=650'
      );
      end();
    } catch (error) {
      end(error);
    }
  }

  const createPaypalOrder = useCallback(async () => {
    start();
    const res = await customFetch(CREATE_ORDER_PAYPAL + pack?.id, {
      method: 'POST',
    });
    if (!res?.ok) throw new Error(await res?.text());
    const data = (await res!.json()).data;
    const order = dataNormalize(data);
    setOrder(order);
    return order.pspInfo!.orderId;
  }, [start, customFetch, pack, setOrder]);

  const capturePaypalOrder = useCallback(
    async ({ orderID }: { orderID: string }) => {
      const res = await customFetch(CAPTURE_PAYMENT_PAYPAL + orderID, {
        method: 'POST',
      });
      if (!res?.ok) throw new Error(await res?.text());
      setOrder((prev) => (prev ? { ...prev, status: 'COMPLETED' } : undefined));
      end();
    },
    [customFetch, setOrder, end]
  );

  return {
    termsAccepted,
    handleAccept,
    handleReject,
    pack,
    loading,
    error,
    renderedTimesRef,
    order,
    createCoinpaymentsOrder,
    createPaypalOrder,
    capturePaypalOrder,
    buttonLoadedCallback: end,
  };
}

function dataNormalize(data: Serialized<Order>): Order {
  return { ...data, date: new Date(data.date) };
}
