import { Link, Box, makeStyles, Typography, Divider } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import { ReactComponent as TikTokIcon } from '../../assets/home/tiktok.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > * ': {
      margin: `0 .5rem`,
    },
  },
  message: {
    color: theme.palette.secondary.contrastText,
    fontSize: '1rem',
    fontWeight: 'bold',
    display: 'inline',
    '@media screen and (max-width: 768px)': {
      fontSize: '0.65rem'
    }
  },
  message2: {
    color: theme.palette.secondary.contrastText,
    fontSize: '1rem',
    fontWeight: 'bold',
    '@media screen and (max-width: 768px)': {
      display: 'none'
    }
  },
  socials: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

function ContactMe() {
  const { root, message, socials, message2 } = useStyles();

  return (
    <Box className={root}>
      <Typography className={message}>Got any question?</Typography>
      <Link className={message} href='mailto:kimysclassroom@gmail.com'>
        Contact <strong>me</strong>
      </Link>
      <Divider orientation='vertical'/>
      <Typography className={message2}>
        Follow me
      </Typography>
      <Link className={socials}  href='https://instagram.com/kimysclassroom?utm_medium=copy_link'>
        <InstagramIcon style={{fill: 'white'}} />
      </Link>
      <Link className={socials} href='https://vm.tiktok.com/ZMRuVVm6N/'>
        <TikTokIcon style={{fill: 'white'}}/>
      </Link>
    </Box>
  );
}

export default ContactMe;
