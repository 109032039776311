import {
    Box,
    Card,
    CardHeader,
    CardContent,
    Avatar,
    Typography,
    makeStyles,
} from '@material-ui/core';
import { ReactComponent as QuoationMarkLeft } from '../../assets/home/testimony/cita-izquierda.svg';
import { ReactComponent as QuoationMarkRight } from '../../assets/home/testimony/cita-derecha.svg';
import { TestimonyInformation } from '../../types/Testimony'

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    header: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
    pricing: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
    },
  }));

function TestimonialCard({ testimony }: Props ) {
    const { header, avatar } = useStyles();
    const { name, photo, country, paragraph } = testimony; 
    return (
        <Card 
            style={{ 
                height: '100%'
            }}
        >
            <CardHeader
            title={name}
            subheader={country}
            avatar={<Avatar className={avatar} alt='student photo' src={photo}/>}
            titleTypographyProps={{ align: 'right' }}
            subheaderTypographyProps={{ align: 'right' }}
            className={header}
            />
            <CardContent>
                <QuoationMarkLeft width='10%' height='10%' stroke='#FF725E'/>
                <Typography align='justify' variant='body1'>{paragraph}</Typography>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    <QuoationMarkRight width='10%' height='10%'/>
                </Box>
            </CardContent>
        </Card>
    )
}

type Props = {
    testimony: TestimonyInformation
}

export default TestimonialCard;