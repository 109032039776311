import { useCallback, useContext, useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import SessionContext from '../contexts/SessionContext';

export default function useRequireAuth(requiredAtMounted = false) {
  const { user } = useContext(SessionContext);
  const location = useLocation();
  const history = useHistory();

  const requireAuth = useCallback(() => {
    if (!user) {
      history.push('/signin', { from: location });
      return true;
    }
    return false;
  }, [user, location, history]);

  useLayoutEffect(() => {
    requiredAtMounted && requireAuth();
  }, [requiredAtMounted, requireAuth]);

  return requireAuth;
}
