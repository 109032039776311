import { useCallback, useEffect, useRef, useState } from 'react';
import useAsyncProcess from '../../hooks/useAsync';
import useCustomFetch from '../../hooks/useCustomFetch';
import { Appointment } from '../../types/Appointment';
import { Serialized } from '../../types/shared';
import { GET_SCHEDULE, CANCEL_APPOINTMENT } from '../../settings/apiEndpoints';
import useRenderedTimes from '../../hooks/useRenderedTimes';

export default function useSchedule() {
  const customFetch = useCustomFetch();
  const { loading, error, start, end } = useAsyncProcess(true);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const renderedTimes = useRenderedTimes();

  const [showLinearLoader, setShowLinearLoader] = useState(loading);

  const timeoutMessageResponseRef = useRef<number>();
  const [cancellingResponse, setCancellingResponse] = useState<
    [boolean, string]
  >();

  // only show linear loader at first load
  useEffect(() => {
    setShowLinearLoader(!renderedTimes && loading);
  }, [loading, renderedTimes]);

  const snackbar = cancellingResponse
    ? {
        severity: cancellingResponse[0] ? 'success' : 'error',
        message: cancellingResponse[1],
      }
    : undefined;

  const fetchAppointments = useCallback(async () => {
    start();
    try {
      const res = await customFetch(GET_SCHEDULE);
      if (!res) return;
      if (!res.ok) throw new Error(await res.text());
      const data = (await res.json()).data;
      const dataNormalized = dataNormalizer(data);
      setAppointments(dataNormalized);
      end();
    } catch (error) {
      end(error);
    }
  }, [customFetch, end, start, setAppointments]);

  useEffect(() => {
    fetchAppointments();
  }, [fetchAppointments]);

  const cancelApopintment = useCallback(
    async (appointmentId) => {
      start();
      try {
        const res = await customFetch(CANCEL_APPOINTMENT + appointmentId, {
          method: 'PATCH',
        });
        if (!res) return;
        if (!res.ok) throw new Error(await res.text());
        updateCancellingResponse(true);
      } catch (error) {
        updateCancellingResponse(false, error.message);
      } finally {
        fetchAppointments();
      }
    },
    [customFetch, start, fetchAppointments]
  );

  return {
    showLinearLoader,
    snackbar,
    loading,
    error,
    appointments,
    cancelApopintment,
    cancellingResponse,
  };

  function updateCancellingResponse(successfully: boolean, message?: string) {
    if (successfully) setCancellingResponse([true, 'Cancelled successfully']);
    else setCancellingResponse([false, message || 'Error']);

    clearTimeout(timeoutMessageResponseRef.current);
    timeoutMessageResponseRef.current = window.setTimeout(
      () => setCancellingResponse(undefined),
      2000
    );
  }
}

type Data = Serialized<Appointment[]>;

function dataNormalizer(data: Data) {
  return data.map<Appointment>((appointment) => ({
    ...appointment,
    date: new Date(appointment.date),
    endDate: new Date(appointment.endDate),
  }));
}
