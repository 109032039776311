import {
  makeStyles,
  Container,
  Typography,
  TextField,
  Link,
  Button,
  Grid,
  Box,
} from '@material-ui/core';
import React, { RefObject } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FaFacebook, FaGoogle } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function UserCredentials(props: Props) {
  const { paper, form, submit } = useStyles();
  const {
    email,
    emailRef,
    password,
    passwordRef,
    handleInputChange,
    handleSubmit,
    signInWithGoogle,
    signInWithFacebook,
  } = props;

  return (
    <Container component='main' maxWidth='xs'>
      <div className={paper}>
        <Box marginBottom={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                onClick={signInWithGoogle}
                fullWidth
                color='primary'
                variant='contained'
                size='large'
                style={{ backgroundColor: 'indianred' }}
              >
                <FaGoogle size='30' />
                <Box marginLeft={1} component='span'>
                  Sign in with Google
                </Box>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={signInWithFacebook}
                fullWidth
                color='secondary'
                variant='contained'
                size='large'
                style={{ backgroundColor: 'dodgerblue' }}
              >
                <FaFacebook size='30' />
                <Box marginLeft={1} component='span'>
                  Sign in with Facebook
                </Box>
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Typography component='h1' variant='h5'>
          or, be traditional
        </Typography>
        <form className={form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            autoFocus
            value={email}
            onChange={handleInputChange}
            inputRef={emailRef}
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            value={password}
            onChange={handleInputChange}
            inputRef={passwordRef}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to='#' variant='body2'>
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link component={RouterLink} to='/signup' variant='body2'>
                Don't have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

type Props = {
  email: string;
  emailRef: RefObject<HTMLInputElement>;
  password: string;
  passwordRef: RefObject<HTMLInputElement>;
  handleInputChange(event: React.ChangeEvent<HTMLInputElement>): void;
  handleSubmit(event: React.FormEvent<HTMLFormElement>): void;
  signInWithGoogle(): void;
  signInWithFacebook(): void;
};

export default UserCredentials;
