import { useRef, useEffect, useCallback } from 'react';
import { PAYPAL_ID } from '../../settings/paypal';

function PaypalButton(props: Props) {
  const paypalRef = useRef(null);

  function addSdk() {
    return new Promise(function (resolve, reject) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://www.paypal.com/sdk/js?client-id=${PAYPAL_ID}`;
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }

  const { createOrder, onApprove, onError, onCancel } = props;
  const renderPaypal = useCallback(() => {
    window.paypal
      .Buttons({
        createOrder,
        onApprove,
        onError,
        onCancel,
        style: {
          layout: 'horizontal',
          color: 'blue',
          shape: 'pill',
          label: 'buynow',
        },
      })
      .render(paypalRef.current);
  }, [createOrder, onApprove, onError, onCancel]);

  const { successfullyLoaded, errorOnLoading } = props;
  useEffect(() => {
    addSdk()
      .then(() => {
        renderPaypal();
        successfullyLoaded();
      })
      .catch(errorOnLoading);
  }, [successfullyLoaded, errorOnLoading, renderPaypal]);

  return <div ref={paypalRef} />;
}

declare global {
  interface Window {
    paypal: any;
  }
}

type PaymentAprovedInfo = {
  orderID: string;
  payerID: string;
  facilitatorAccessToken: string;
  paymentID: string | null;
  billingToken: string | null;
};

type Props = {
  createOrder(): Promise<string>;
  onApprove(info: PaymentAprovedInfo): Promise<void>;
  onError(error: Error): void;
  onCancel(): void;
  successfullyLoaded(): void;
  errorOnLoading(error: Error): void;
};

export default PaypalButton;
