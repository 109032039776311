import { useContext, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { formatDistanceToNow, format } from 'date-fns';
import AgendaContext from '../../../contexts/AgendaContext';

const TableCell = withStyles((theme) => ({
  root: {
    cursor: 'default',
    userSelect: 'none',

    '&.MuiTableCell-head': {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
    },
  },
}))(MuiTableCell);

function ActivePacks() {
  const activePacks = useContext(AgendaContext).activePacks!;
  const [expanded, setExpanded] = useState(false);

  if (!activePacks.length) return null;

  const totalClassesLeft = activePacks.reduce(
    (accum, pack) => accum + pack.classesLeft,
    0
  );

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
    >
      <AccordionSummary expandIcon={<ExpandMore color='secondary' />}>
        <Typography>
          You have <strong>{totalClassesLeft}</strong> lessons pending for
          booking
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Pack</TableCell>
              <TableCell align='center'>Expires on</TableCell>
              <TableCell align='right'>Lessons</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activePacks.map((pack) => (
              <Tooltip
                title={`Expires on ${format(
                  pack.endDate,
                  `LLLL do 'at' hh:mm aaa`
                )}`}
                arrow
                key={pack.id}
              >
                <TableRow>
                  <TableCell align='left'>{pack.description}</TableCell>
                  <TableCell align='center'>
                    {formatDistanceToNow(pack.endDate)}
                  </TableCell>
                  <TableCell align='right'>{pack.classesLeft}</TableCell>
                </TableRow>
              </Tooltip>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
}

export default ActivePacks;
