import { Container, Box, Grid, Typography } from "@material-ui/core";
import TestimonialCard from "./testimonialCard";

// student photos
import Major from '../../assets/home/testimony/students/Major.jpeg';
import Anna from '../../assets/home/testimony/students/Anna.jpeg';
import Taylor from '../../assets/home/testimony/students/Taylor.jpeg';
import Nicky from '../../assets/home/testimony/students/Nicky.jpeg';

const studentsSay = [
    {
        id: 1,
        name: 'Nicky',
        country: 'Nueva York, EEUU',
        photo: `${Nicky}`,
        paragraph: `Vivo en Nueva York - hay muchas oportunidades para hablar español aquí. Estudio con Kim desde hace 4 meses. 
        Nuestras clases son particulares y recibo mucha atención. Me encanta poder preguntarle a Kim cosas cuando salgo - ella es de mucha ayuda! Buscaba una maestra... ¡Y encontré una nueva amiga también!`
    },
    {
        id: 2,
        name: 'Anna',
        country: ' Arizona, EEUU',
        photo: `${Anna}`,
        paragraph: 'Tomé clases de español en el colegio, y por algunos años he tratado de mantener mi comprensión. Estoy estudiando con Kimy y me ayuda mucho porque puedo estudiar semanalmente con un horario flexible. Kim es fantástica, sus clases son divertidas e interesantes.'
    },
    {
        id: 3,
        name: 'Major',
        country: 'Pensilvania, EEUU',
        photo: `${Major}`,
        paragraph: 'Estoy en el Aula de Kimy desde mayo. Comienza preguntando tus objetivos y la razón por la que deseas aprender español. Se enfoca en tus necesidades y en como la gente normalmente se relaciona. Entiendo mucho mejor cómo se perciben ciertas frases. También aprendí un poco de historia sobre diferentes países de América del Sur.'
    },
    {
        id: 4,
        name: 'Taylor',
        country: 'Arkansas, EEUU',
        photo: `${Taylor}`,
        paragraph: 'He estudiado con Kimy por un año. Gracias a ella, he tenido la seguridad para viajar muchos países hispanos. ¡Tres países y tengo más planes! Las lecciones siempre son interesantes y divertidas. Kimberly hace las clases con cuidado y las disfrutarás todas.'
    },

]


function Testimonials() {
    return (
        <Box padding={3}>
            <Typography color='primary' variant='h1' align='center' gutterBottom>
                What my students think about me?
            </Typography>
            <Container maxWidth='lg'>
                <Grid container spacing={3}    justify='center' >
                    {studentsSay.map((testimony) => (
                        <Grid key={testimony.id} item xs={12} sm={6} md={3}>
                            <TestimonialCard  testimony={testimony}/>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    )
}

export default Testimonials;