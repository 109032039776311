import { ReactNode } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert, { AlertProps } from '@material-ui/lab/Alert';

function CustomSnackbar(props: Props) {
  const { open, handleClose, severity, children, duration } = props;

  if (!open) return null;

  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} variant='filled'>
        {children}
      </Alert>
    </Snackbar>
  );
}

type Props = {
  open: boolean;
  duration?: number;
  severity?: AlertProps['severity'];
  handleClose?(): void;
  children: ReactNode;
};

export default CustomSnackbar;
