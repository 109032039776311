import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SessionContext from '../../contexts/SessionContext';
import useAsyncProcess from '../../hooks/useAsync';
import { auth } from '../../settings/firebase';

export default function useSignIn() {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    password: '',
    repassword: '',
  });
  const { name, email, password, repassword } = userData;
  const [userCreated, setUserCreated] = useState(false);
  const [repasswordMatch, setRepasswordMatch] = useState<boolean>();
  const nameFieldRef = useRef<HTMLInputElement>(null);
  const emailFieldRef = useRef<HTMLInputElement>(null);
  const passwordFieldRef = useRef<HTMLInputElement>(null);
  const repasswordFieldRef = useRef<HTMLInputElement>(null);

  const { loading, error, start, end } = useAsyncProcess();
  const { user, service } = useContext(SessionContext);
  const { unsubscribeRef } = service!;

  const history = useHistory();
  const pathFrom =
    useLocation<{ from: Location } | undefined>().state?.from.pathname || '/';

  useEffect(() => {
    if (user) history.replace(pathFrom);
  }, [user, history, pathFrom]);

  useEffect(() => {
    if (password && repassword) setRepasswordMatch(password === repassword);
    else setRepasswordMatch(undefined);
  }, [password, repassword]);

  return {
    name,
    nameFieldRef,
    email,
    emailFieldRef,
    password,
    passwordFieldRef,
    repassword,
    repasswordFieldRef,
    repasswordMatch,
    handleInputChange,
    handleSubmit,
    loading,
    error,
    userCreated,
  };

  async function signUp() {
    start();
    unsubscribeRef.current && unsubscribeRef.current();
    debugger;
    try {
      await auth.createUserWithEmailAndPassword(email, password);
      const { currentUser } = auth;
      if (!currentUser) throw new Error('User not created');
      await currentUser.updateProfile({ displayName: name });
      await currentUser.sendEmailVerification();
      await auth.signOut();
      setUserCreated(true);
      end();
    } catch (error) {
      end(error);
    }
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setUserData((userData) => ({ ...userData, [name]: value }));
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    switch (true) {
      case !name:
        nameFieldRef.current?.focus();
        return;
      case !email:
        emailFieldRef.current?.focus();
        return;
      case !password:
        passwordFieldRef.current?.focus();
        return;
      case !repasswordMatch:
        repasswordFieldRef.current?.focus();
        return;
    }

    signUp();
  }
}
