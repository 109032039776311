import { ReactComponent as SignUpIcon } from '../../assets/home/how-it-works/signup.svg';
import { ReactComponent as EnjoyTrialLessonIcon } from '../../assets/home/how-it-works/enjoy-trial-lesson.svg';
import { ReactComponent as ChooseLessonPackIcon } from '../../assets/home/how-it-works/choose-lesson-pack.svg';
import { ReactComponent as BienvenidoIcon } from '../../assets/home/how-it-works/bienvenido.svg';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import Badge from './Badge';

const steps = [
  {
    title: 'Sign up',
    description: 'Using your Google or Facebook account.',
    Icon: SignUpIcon,
  },
  {
    title: 'Enjoy your Trial Lesson',
    description:
      'Go to Agenda and book it. Check your Schedule for more info. You will get a reminder before the lesson on your e-mail.',
    Icon: EnjoyTrialLessonIcon,
  },
  {
    title: 'Choose Lesson Packages',
    description:
      'Once you buy a package, you have one month to use all of your lessons. Just pick the package that suits you better and book your session!',
    Icon: ChooseLessonPackIcon,
  },
  {
    title: '¡Bienvenido!',
    description: `And that's it! Remember to enjoy your journey!`,
    Icon: BienvenidoIcon,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'lightgray',
    padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`,

    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(4)}px ${theme.spacing(1)}px`,
    },
  },
}));

function HowItWorks() {
  const { root } = useStyles();

  return (
    <Box className={root}>
      <Typography color='primary' variant='h1' align='center' gutterBottom>
        How it works?
      </Typography>
      <Grid container>
        {steps.map((step) => (
          <Grid item xs={6} md={3} key={step.title}>
            <Badge {...step} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default HowItWorks;
