import {
  Box,
  Button,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ReactComponent as OnlineSpanishImage } from '../../assets/home/online-spanish-lessons.svg';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  button: {
    width: theme.spacing(15),
  },
}));

function OnlineSpanish() {
  const { button } = useStyles();

  return (
    <Box padding={3}>
      <Grid container spacing={3} alignItems='center'>
        <Grid item xs={12} sm={6}>
          <Typography color='primary' variant='h1' align='center' gutterBottom>
            Online Spanish Lessons
          </Typography>
          <Box>
            <Typography align='center' paragraph>
              Are you looking for a new hobby?
              <br />
              Moving away or traveling this summer?
              <br />
              Do you want to land your dream job?
              <br />
              Is not knowing sufficient Spanish what's holding you back?
            </Typography>
            <Typography align='center' paragraph>
              Welcome to <strong>Kimy's Classroom</strong>!
              <br />I am Kim and I will help you achieve your goals.
            </Typography>
          </Box>
          <Grid container spacing={2} justify='center'>
            <Grid item>
              <Button
                className={button}
                component={RouterLink}
                to='/agenda'
                color='primary'
                variant='contained'
                fullWidth
              >
                book now
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={button}
                component={RouterLink}
                to='/packs'
                color='primary'
                variant='outlined'
                fullWidth
              >
                pricing
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Hidden xsDown>
          <Grid item sm={6}>
            <Box
              style={{
                height: '350px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <OnlineSpanishImage width='100%' height='100%' />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  );
}

export default OnlineSpanish;
