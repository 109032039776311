import { useEffect, useRef } from 'react';

export default function useRenderedTimes() {
  const renderedTimesRef = useRef(0);

  useEffect(() => {
    renderedTimesRef.current++;
  });

  return renderedTimesRef.current;
}
