import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: `'Helvetica Neue', Helvetica, Arial, sans-serif`,
    textAlign: 'center',
    userSelect: 'none',
  },
  button: {
    width: '100%',
    height: '35px',
    borderRadius: '28px',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: '#ffc439',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1rem',

    [theme.breakpoints.down('sm')]: {},
  },
  text: {
    height: '18px',
    lineHeight: '18px',
    whiteSpace: 'pre',
    verticalAlign: 'top',
  },
}));

function CoinpaymentsButton({ onClick }: Props) {
  const { root, button, text } = useStyles();
  return (
    <div className={root}>
      <button className={button} onClick={onClick}>
        Pay with bitcoin
      </button>
      <span className={text}>The coolest way to pay</span>
    </div>
  );
}

type Props = {
  onClick(): void;
};

export default CoinpaymentsButton;
