import { Container, Button } from '@material-ui/core';

function EmailNotVerified({ sendConfirmationEmail }: Props) {
  return (
    <Container>
      <h1>your email is not verified</h1>
      <Button
        onClick={sendConfirmationEmail}
        color='primary'
        variant='contained'
      >
        resend confirmation email
      </Button>
    </Container>
  );
}

type Props = {
  sendConfirmationEmail(): void;
};

export default EmailNotVerified;
