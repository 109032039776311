import {
  makeStyles,
  Grid,
  Container,
  Box,
  Typography,
  Hidden,
  Button,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { ReactComponent as CalendarImage } from '../../assets/schedule/calendar.svg';
import Lesson from './Lesson';
import useSchedule from './useSchedule';
import CustomSnackbar from '../shared/CustomSnackbar/CustomSnackbar';
import LoaderBackdrop from '../shared/BackdropLoader/BackdropLoader';
import { Appointment } from '../../types/Appointment';
import { Link as RouterLink } from 'react-router-dom';
import { withAuthRequired } from '../shared/hoc/withAuthRequired';
import ErrorComponent from '../shared/ErrorComponent/ErrorComponent';
import { useState } from 'react';
import { addHours, isFuture } from 'date-fns';
import { MIN_HOURS_AHEAD_REFUND_CANCELLED_APPOINTMENT } from '../../settings/kimysclassroom';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  button: {
    width: theme.spacing(15),
  },
  notRefundable: {
    color: theme.palette.error.main,
  },
}));

function Schedule(props: Props) {
  const { button, notRefundable } = useStyles();
  const { appointments, cancelApopintment } = props;
  const [appointmentToCancel, setAppointmentToCancel] = useState<Appointment>();

  const appointmentsQuantity = appointments.length;

  if (!appointmentsQuantity)
    return (
      <Box padding={3}>
        <Grid container spacing={3} direction='column' alignItems='center'>
          <Grid item>
            <Typography variant='h2' color='primary' gutterBottom>
              It seems like you don't have any future lesson yet
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2} justify='center'>
              <Grid item>
                <Button
                  className={button}
                  component={RouterLink}
                  to='/agenda'
                  color='primary'
                  variant='contained'
                  fullWidth
                >
                  book now
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={button}
                  component={RouterLink}
                  to='/packs'
                  color='primary'
                  variant='outlined'
                  fullWidth
                >
                  pricing
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box>
              <CalendarImage width='100%' />
            </Box>
          </Grid>
        </Grid>
      </Box>
    );

  const isRefundable =
    appointmentToCancel &&
    isFuture(
      addHours(
        appointmentToCancel.date,
        -MIN_HOURS_AHEAD_REFUND_CANCELLED_APPOINTMENT
      )
    );

  return (
    <>
      {isRefundable !== undefined && (
        <Dialog
          open={!!appointmentToCancel}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            Are you sure you want to cancel this lesson?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {isRefundable ? (
                <Typography>
                  Don't worry. The lesson will be waiting for you in the
                  pack! You can book it again at a more convenient time
                </Typography>
              ) : (
                <Typography className={notRefundable}>
                  Sorry! Looks like you missed the cancelling window. This
                  lesson is not available for refunding.
                </Typography>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              onClick={() => setAppointmentToCancel(undefined)}
            >
              no
            </Button>
            <Button
              onClick={() => {
                cancelApopintment(appointmentToCancel!.id);
                setAppointmentToCancel(undefined);
              }}
              color='primary'
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Container maxWidth='lg'>
        <Box marginY={3}>
          <Typography variant='h2' color='primary'>
            Your next class is in...
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={12} sm={appointmentsQuantity === 1 ? 7 : 8}>
            <Grid container spacing={2}>
              {appointments.map((appointment, index) => (
                <Grid item xs={12} md={6} key={appointment.id}>
                  <Lesson
                    appointment={appointment}
                    cancelAppointment={() =>
                      setAppointmentToCancel(appointment)
                    }
                    closest={!index}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={appointmentsQuantity === 1 ? 5 : 4}>
              <Box
                padding={2}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CalendarImage height='100%' width='100%' />
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </>
  );
}

type Props = {
  appointments: Appointment[];
  cancelApopintment(id: string): void;
};

function ScheduleWrapper() {
  const {
    showLinearLoader,
    snackbar,
    loading,
    error,
    ...scheduleProps
  } = useSchedule();

  if (error) return <ErrorComponent>{error}</ErrorComponent>;
  if (showLinearLoader) return <LinearProgress />;

  return (
    <>
      <Helmet>
        <title>Your Schedule | Kimys'classroom</title>
      </Helmet>
      <LoaderBackdrop open={loading} />
      <Schedule {...scheduleProps} />
      <CustomSnackbar
        open={!!snackbar}
        severity={snackbar?.severity as 'success' | 'error'}
        children={snackbar?.message}
      />
    </>
  );
}

export default withAuthRequired(ScheduleWrapper);
