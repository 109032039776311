// const BASE_URL = "http://localhost:3001";
const BASE_URL = 'https://api.kimysclassroom.com';

export const GET_AGENDA = BASE_URL + '/calendar';

export const BOOK_APPOINTMENT = BASE_URL + '/appointments';

export const GET_SCHEDULE = BASE_URL + '/appointments/next';

export const CANCEL_APPOINTMENT = BASE_URL + '/appointments/';

export const GET_PACKS_OFFER = BASE_URL + '/packs-offer';

export const GET_LESSONS_HISTORY = BASE_URL + '/appointments';

export const GET_PACKS_HISTORY = BASE_URL + '/packs';

export const GET_PENDING_ORDERS = BASE_URL + '/orders';

export const CREATE_ORDER_PAYPAL = BASE_URL + '/orders/place/paypal/';

export const CAPTURE_PAYMENT_PAYPAL = BASE_URL + '/orders/capture/paypal/';

export const NOTIFY_SIGNIN = BASE_URL + '/signin';

export const CREATE_ORDER_COINPAYMENTS =
  BASE_URL + '/orders/place/coinpayments/';
