import { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

function TripleDotButton({ options }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(undefined);
  }

  return (
    <div>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
        size='small'
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map(([value, clickHandler]) => (
          <MenuItem
            key={value}
            onClick={() => {
              handleClose();
              clickHandler();
            }}
          >
            {value}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

type Props = {
  options: [string, Function][];
};

export default TripleDotButton;
