import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import {
  isSameMonth,
  addDays,
  lastDayOfMonth,
  setDate,
  format,
} from 'date-fns';
import { useContext } from 'react';
import AgendaContext from '../../../contexts/AgendaContext';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  daysContainer: {
    borderTop: `1px solid ${theme.palette.secondary.contrastText}`,
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    textAlign: 'center',
  },
  iconButton: {
    color: theme.palette.primary.contrastText,
    '&.disabled': {
      opacity: '50%',
    },
  },
}));

const DAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

function CalendarHeader() {
  const { root, daysContainer, iconButton } = useStyles();
  const contextValue = useContext(AgendaContext);
  const { agenda, selectedDate, setSelectedDate } = contextValue as Required<
    typeof contextValue
  >;

  const agendaDates = Object.keys(agenda);
  const closestDate = new Date(parseInt(agendaDates[0]));
  const farthestDate = new Date(parseInt(agendaDates[agendaDates.length - 1]));

  const canGoMonthBackward = !isSameMonth(selectedDate, closestDate);
  const canGoMonthForward = !isSameMonth(selectedDate, farthestDate);

  return (
    <div className={root}>
      <Box padding={2}>
        <Grid container justify='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h4'>{format(selectedDate, 'MMMM')}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              disabled={!canGoMonthBackward}
              onClick={handleMonthBackward}
            >
              <KeyboardArrowLeft
                className={`${iconButton}${
                  !canGoMonthBackward ? ' disabled' : ''
                }`}
              />
            </IconButton>
            <IconButton
              disabled={!canGoMonthForward}
              onClick={handleMonthForward}
            >
              <KeyboardArrowRight
                className={`${iconButton}${
                  !canGoMonthForward ? ' disabled' : ''
                }`}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <div className={daysContainer}>
        {DAYS.map((dayName) => (
          <span key={dayName}>{dayName}</span>
        ))}
      </div>
    </div>
  );

  function handleMonthBackward() {
    if (canGoMonthBackward)
      setSelectedDate(addDays(setDate(selectedDate, 1), -1));
  }

  function handleMonthForward() {
    if (canGoMonthForward)
      setSelectedDate(addDays(lastDayOfMonth(selectedDate), +1));
  }
}

export default CalendarHeader;
