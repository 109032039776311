import { Box, Container, Typography, Button } from '@material-ui/core';
import { Order } from '../../types/Order';
import { Link as RouterLink } from 'react-router-dom';

function ProcessDone({ order, packName }: Props) {
  const isCompleted = order.status === 'COMPLETED';

  return (
    <Box marginTop={4}>
      <Container maxWidth='sm'>
        <Typography variant='h2' align='center' color='primary' gutterBottom>
          {isCompleted
            ? 'Thanks for your purchase!'
            : 'Your order has been placed!'}
        </Typography>
        <Typography align='center' color='textSecondary' gutterBottom>
          {isCompleted
            ? `${packName} pack is now active`
            : `${packName} pack is pending until payment is confirmed`}
        </Typography>
        <Box
          marginTop={5}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Button
            component={RouterLink}
            variant='outlined'
            color='primary'
            to={isCompleted ? '/agenda' : '/account/packs-history'}
          >
            {isCompleted ? 'book now' : 'check pack status'}
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

type Props = {
  order: Order;
  packName: string;
};

export default ProcessDone;
