import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

function TermsAndConditions(props: Props) {
  const { open, handleAccept, handleReject } = props;

  return (
    <Dialog
      open={open}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogTitle id='scroll-dialog-title'>Terms & Conditions</DialogTitle>
      <DialogContent>
        <Content />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReject} color='primary'>
          reject
        </Button>
        <Button onClick={handleAccept} color='primary'>
          accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type Props = {
  open: boolean;
  handleAccept(): void;
  handleReject(): void;
};

export default TermsAndConditions;

function Content() {
  return (
    <>
      <DialogContentText>
        These terms &amp; conditions apply to anyone starting a course with
        “Kimy’s Classroom’’.
      </DialogContentText>
      <DialogContentText>
        Kimy’s Classroom may change the content, which includes prices, offers
        and content at any time. We do not provide a warranty or guarantee of
        its accuracy, completeness, suitability, timeliness or performance of
        information or content in the website. If there are errors in the
        information provided in the content of the website we expressly exclude
        liability for these inaccuracies, to the fullest extent that the law
        permits.
      </DialogContentText>
      <DialogContentText>
        You can use any of the information or content at your own risk for which
        Kimy’s Classroom will not be liable.
      </DialogContentText>
      <Box paddingLeft={4} component='ol'>
        <DialogContentText component='li'>
          Payment
          <Box
            paddingLeft={2}
            component='ol'
            style={{ listStyleType: 'lower-alpha' }}
          >
            <DialogContentText component='li'>
              All prices that are advertised on our website are correct at the
              time of booking. However, Kimy’s Classroom reserves the right and
              discretion to change our prices at any time.
            </DialogContentText>
            <DialogContentText component='li'>
              Once the student has paid for a class package, they will have 30
              days to use all the classes. If the classes are not used after 30
              days, they will be removed from the account.
            </DialogContentText>
          </Box>
        </DialogContentText>
        <DialogContentText component='li'>
          Booking
          <Box
            paddingLeft={2}
            component='ol'
            style={{ listStyleType: 'lower-alpha' }}
          >
            <DialogContentText component='li'>
              Bookings are made online on our website www.kimysclassroom.com.
              One hour before the class the student will receive a reminder via
              email with the link. Kimy’s Classroom will only deal with the
              student in the event of cancellations due to major causes.
            </DialogContentText>
            <DialogContentText component='li'>
              Classes can be booked up to five hours in advance.
            </DialogContentText>
            <DialogContentText component='li'>
              Classes can be cancelled one hour before scheduled. If the class
              is cancelled less than an hour, it will count as a viewed class.
            </DialogContentText>
          </Box>
        </DialogContentText>
        <DialogContentText component='li'>
          Cancelling and Refunds
          <Box
            paddingLeft={2}
            component='ol'
            style={{ listStyleType: 'lower-alpha' }}
          >
            <DialogContentText component='li'>
              The student has a free class when registering to the website so
              they can experience it before paying for a package. The packages
              are 100% non- refundable.
            </DialogContentText>
          </Box>
        </DialogContentText>
      </Box>
      <DialogContentText>
        By clicking on the “Accept” button the student agrees to these terms. If
        the student does not agree to these terms and conditions they must cease
        to continue to purchase any Services from us.
      </DialogContentText>
    </>
  );
}
