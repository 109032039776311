import { ReactComponent as FreeTrialLessonIcon } from '../../assets/home/features/free-trial-lesson.svg';
import { ReactComponent as LiveScheduleIcon } from '../../assets/home/features/live-schedule.svg';
import { ReactComponent as AtYourOwnPaceIcon } from '../../assets/home/features/your-own-pace.svg';
import { ReactComponent as CustomizedLessonsIcon } from '../../assets/home/features/customized-lessons.svg';
import { Grid, Box, makeStyles } from '@material-ui/core';
import Badge from './Badge';

const features = [
  {
    title: 'Customized Lessons',
    description: 'According to your needs',
    Icon: CustomizedLessonsIcon,
  },
  {
    title: 'At your own pace',
    description: 'Gain confidence learning at your own rhythm',
    Icon: AtYourOwnPaceIcon,
  },
  {
    title: 'Live schedule',
    description: 'Check my 24/7 agenda to book your classes',
    Icon: LiveScheduleIcon,
  },
  {
    title: 'Free trial lesson',
    description: "Let's meet before we start this journey",
    Icon: FreeTrialLessonIcon,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'lightgray',
    padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`,

    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(4)}px ${theme.spacing(1)}px`,
    },
  },
}));

function Features() {
  const { root } = useStyles();

  return (
    <Box className={root}>
      <Grid container>
        {features.map((feature) => (
          <Grid item xs={6} md={3} key={feature.title}>
            <Badge {...feature} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Features;
