import {
  Button,
  Grid,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { format } from 'date-fns';
import AgendaItemButton from './AgendaItemButton';
import useDayAgenda from './useDayAgenda';

function DayAgenda() {
  const {
    agendaItems,
    isSelected,
    handleAppointmentBooking,
    showDialog,
    dialogMessage,
    acceptDialogHandler,
    rejectDialogHandler,
    selectedAgendaItem,
    selectedDate,
    setSelectedAgendaItem,
  } = useDayAgenda();

  return (
    <Box>
      <Dialog
        open={showDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Active valid pack not found
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {dialogMessage.split('\n').map((line) => (
              <Typography gutterBottom key={line}>
                {line}
              </Typography>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={rejectDialogHandler} color='primary'>
            Cancel
          </Button>
          <Button onClick={acceptDialogHandler} color='primary' autoFocus>
            Buy Pack
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant='h4' color='primary'>
        Day's agenda
      </Typography>
      <Typography variant='h6' color='secondary'>
        {format(selectedDate, `cccc, LLLL do 'of' y`)}
      </Typography>
      <Box padding={2}>
        <Grid container spacing={2}>
          {agendaItems.map((item) => (
            <Grid item xs={6} key={item.date.valueOf()}>
              <AgendaItemButton
                item={item}
                isSelected={isSelected(item)}
                onClick={() => setSelectedAgendaItem(item)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {selectedAgendaItem && (
        <Button
          fullWidth
          variant='contained'
          color='secondary'
          onClick={handleAppointmentBooking}
        >
          Book it
        </Button>
      )}
    </Box>
  );
}

export default DayAgenda;
