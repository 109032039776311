import { Container, Button } from '@material-ui/core';

function ConfirmationEmailSent({ tryAgain }: Props) {
  return (
    <Container>
      <h1>email confirmation sent</h1>
      <Button onClick={tryAgain} color='primary' variant='contained'>
        try again
      </Button>
    </Container>
  );
}

type Props = {
  tryAgain(): void;
};

export default ConfirmationEmailSent;
