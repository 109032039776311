import { ComponentType } from 'react';
import useRequireAuth from '../../../hooks/useRequireAuth';

export function withAuthRequired<T>(WrappedComponent: ComponentType<T>) {
  function WrappedComponentWithAuthRequired(props: T) {
    const requireAuth = useRequireAuth();
    if (requireAuth()) return null;
    return <WrappedComponent {...props} />;
  }

  return WrappedComponentWithAuthRequired;
}
