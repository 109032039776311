import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Button,
  makeStyles,
} from '@material-ui/core';
import { Star } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { PackInformation } from '../../types/PackOffer';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
    strong: {
      color: theme.palette.primary.main,
    },
  },
  header: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  pricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
}));
function PackInformationCard({ pack, mostPopular }: Props) {
  const { header, pricing } = useStyles();
  const { name, description, price, classesQuantity, daysDuration } = pack;

  const lessonPrice = price / classesQuantity;
  const lessonPriceFormatted = lessonPrice.toFixed(2);

  return (
    <Card>
      <CardHeader
        title={name}
        subheader={mostPopular ? 'Most Popular' : undefined}
        titleTypographyProps={{ align: 'center' }}
        subheaderTypographyProps={{ align: 'center' }}
        action={mostPopular ? <Star /> : null}
        className={header}
      />
      <CardContent>
        <div className={pricing}>
          <Typography component='h2' variant='h3' color='textPrimary'>
            {lessonPriceFormatted}
          </Typography>
          <Typography variant='h6' color='textSecondary'>
            $/lesson
          </Typography>
        </div>
        <ul>
          {classesQuantity > 1 && (
            <Typography component='li' variant='subtitle1' align='center'>
              <strong>{classesQuantity}</strong> lesson{classesQuantity > 1 && "s"}
            </Typography>
          )}
          <Typography component='li' variant='subtitle1' align='center'>
            {description}
          </Typography>
          <Typography component='li' variant='subtitle1' align='center'>
            {daysDuration} days to book {classesQuantity === 1 ? "it" : "them"}
          </Typography>
          <Typography component='li' variant='subtitle1' align='center'>
            Friendly reminder before the lesson starts
          </Typography>
          {classesQuantity > 1 && (
            <Typography component='li' variant='subtitle1' align="center">
              Option to book with 5 hours in advance
            </Typography>
          )}
          {classesQuantity > 1 && (
            <Typography component='li' variant='subtitle1' align='center'>
              Access to all PDFs used in classes
            </Typography>
          )}
          {classesQuantity > 8 && (
            <Typography component='li' variant='subtitle1' align='center'>
              Option to cancel with 2 hours in advance
            </Typography>
          )}
          <Typography component='li' variant='subtitle1' align='center'>
            Total <strong>{price.toFixed(2)}$</strong>
          </Typography>
        </ul>
      </CardContent>
      <CardActions>
        <Button
          fullWidth
          color='primary'
          component={RouterLink}
          variant='outlined'
          to={{ pathname: '/checkout', state: { pack } }}
        >
          buy
        </Button>
      </CardActions>
    </Card>
  );
}

type Props = {
  pack: PackInformation;
  mostPopular: boolean;
};

export default PackInformationCard;
