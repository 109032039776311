import { Button as MuiButton, Tooltip, withStyles } from '@material-ui/core';
import { AgendaItem } from '../../../types/Agenda';
import { format } from 'date-fns';

const Button = withStyles((theme) => ({
  root: {
    '&': {
      width: '100%',
    },
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
    '&.selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        cursor: 'unset',
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&.oneself': {
      backgroundColor: theme.palette.primary.light,
      opacity: '0.8',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    '&.someone': {
      backgroundColor: 'lightgray',
      opacity: '0.8',
      '&:hover': {
        backgroundColor: 'lightgray',
      },
    },
  },
}))(MuiButton);

function AgendaItemButton(props: Props) {
  const {
    item: { bookedBy, date, endDate },
    isSelected,
    onClick,
  } = props;

  const disabled = bookedBy !== 'none';

  const tooltipText =
    bookedBy === 'someone'
      ? 'booked by someone else'
      : bookedBy === 'oneself'
      ? 'booked by yourself'
      : isSelected
      ? 'selected to be booked'
      : 'available';

  const adjustedButtonProps = {
    disabled,
    component: disabled ? 'div' : undefined,
    onClick: disabled ? undefined : onClick,
    className: `${bookedBy}${isSelected ? ' selected' : ''}`,
  };

  const template = 'hh:mmaaa';
  const from = format(date, template);
  const to = format(endDate, template);

  return (
    <Tooltip title={tooltipText} arrow>
      <Button
        color='primary'
        variant={!disabled ? 'outlined' : undefined}
        {...adjustedButtonProps}
      >
        {from}
        <br />
        {to}
      </Button>
    </Tooltip>
  );
}

type Props = {
  item: AgendaItem;
  isSelected: boolean;
  onClick(): void;
};

export default AgendaItemButton;
