import { useContext } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { CssBaseline, Toolbar, useMediaQuery, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { AgendaContextProvider } from '../contexts/AgendaContext';
import SessionContext, { SessionProvider } from '../contexts/SessionContext';
import theme from './theme';
import Home from '../components/Home/Home';
import Schedule from '../components/Schedule/Schedule';
import Header from '../components/shared/Header/Header';
import LoaderBackdrop from '../components/shared/BackdropLoader/BackdropLoader';
import Agenda from '../components/Agenda/Agenda';
import PacksOffer from '../components/PacksOffer/PacksOffer';
import Checkout from '../components/Checkout/Checkout';
import SignIn from '../components/SignIn/SignIn';
import SignUp from '../components/SignUp/SignUp';
import Account from '../components/Account/Account';

function App() {
  const { user } = useContext(SessionContext);
  const navOnTop = useMediaQuery(theme.breakpoints.up(theme.navbar.breakpoint));

  if (user === undefined) return <LoaderBackdrop open={true} />;

  return (
    <>
      <CssBaseline />
      <Header />
      <Switch>
        <Route path='/packs' component={PacksOffer} />
        <Route path='/checkout' component={Checkout} />
        <Route path='/agenda' component={Agenda} />
        <Route path='/schedule' component={Schedule} />
        <Route path='/signin' component={SignIn} />
        <Route path='/signup' component={SignUp} />
        <Route path='/account' component={Account} />
        <Route path='/' component={Home} />
      </Switch>
      {!navOnTop && (
        <Box marginTop={1}>
          <Toolbar />
        </Box>
      )}
    </>
  );
}

export default function AppWrapper() {
  return (
    <BrowserRouter>
      <SessionProvider>
        <AgendaContextProvider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </AgendaContextProvider>
      </SessionProvider>
    </BrowserRouter>
  );
}
